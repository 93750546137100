import React from "react";
import { Link } from "gatsby";

import { Typography } from "@material-ui/core";

import Layout from "../../components/Layout";
import Footer from "../../components/Footer";

const TermsPage = () => {
  return (
    <Layout title="Reconcile Terms of Service" noBottomNav>
      <Typography variant="h5">TERMS OF SERVICE</Typography>
      <br />
      <br />
      Last updated: 05/12/2020
      <br />
      <br />
      1. Introduction
      <br />
      Welcome to GOLEARY LLC (“Company”, “we”, “our”, “us”)! As you have just
      clicked to our Terms of Service, please make a pause, grab a cup of coffee
      and carefully read the following pages. It will take you approximately 20
      minutes.
      <br />
      These Terms of Service (“Terms”, “Terms of Service”) govern your use of
      our web pages located at https://reconcile.app/ operated by GOLEARY LLC.
      <br />
      <br />
      Our Privacy Policy also governs your use of our Service and explains how
      we collect, safeguard and disclose information that results from your use
      of our web pages. Please read it <Link to="/privacy">here</Link>
      <br />
      <br />
      Your agreement with us includes these Terms and our Privacy Policy
      (“Agreements”). You acknowledge that you have read and understood
      Agreements, and agree to be bound of them.
      <br />
      <br />
      If you do not agree with (or cannot comply with) Agreements, then you may
      not use the Service, but please let us know by emailing at
      oleary.gabe@gmail.com so we can try to find a solution. These Terms apply
      to all visitors, users and others who wish to access or use Service.
      <br />
      <br />
      Thank you for being responsible.
      <br />
      <br />
      2. Communications By creating an Account on our Service, you agree to
      subscribe to newsletters, marketing or promotional materials and other
      information we may send. However, you may opt out of receiving any, or
      all, of these communications from us by following the unsubscribe link or
      by emailing at.
      <br />
      <br />
      3. Fee Changes GOLEARY LLC, in its sole discretion and at any time, may
      modify Subscription fees for the Subscriptions. Any Subscription fee
      change will become effective at the end of the then-current Billing Cycle.
      <br />
      <br />
      GOLEARY LLC will provide you with a reasonable prior notice of any change
      in Subscription fees to give you an opportunity to terminate your
      Subscription before such change becomes effective.
      <br />
      <br />
      Your continued use of Service after Subscription fee change comes into
      effect constitutes your agreement to pay the modified Subscription fee
      amount.
      <br />
      <br />
      4. Refunds
      <br />
      We issue refunds for Contracts within ten (10) days of the original
      purchase of the Contract.
      <br />
      <br />
      5. Content
      <br />
      <br />
      6. Prohibited Uses
      <br />
      You may use Service only for lawful purposes and in accordance with Terms.
      You agree not to use Service:
      <br />
      (a) In any way that violates any applicable national or international law
      or regulation.
      <br />
      <br />
      (b) For the purpose of exploiting, harming, or attempting to exploit or
      harm minors in any way by exposing them to inappropriate content or
      otherwise.
      <br />
      <br />
      (c) To transmit, or procure the sending of, any advertising or promotional
      material, including any “junk mail”, “chain letter,” “spam,” or any other
      similar solicitation.
      <br />
      <br />
      (d) To impersonate or attempt to impersonate Company, a Company employee,
      another user, or any other person or entity.
      <br />
      <br />
      (e) In any way that infringes upon the rights of others, or in any way is
      illegal, threatening, fraudulent, or harmful, or in connection with any
      unlawful, illegal, fraudulent, or harmful purpose or activity.
      <br />
      <br />
      (f) To engage in any other conduct that restricts or inhibits anyone’s use
      or enjoyment of Service, or which, as determined by us, may harm or offend
      Company or users of Service or expose them to liability.
      <br />
      <br />
      Additionally, you agree not to:
      <br />
      <br />
      (a) Use Service in any manner that could disable, overburden, damage, or
      impair Service or interfere with any other party’s use of Service,
      including their ability to engage in real time activities through Service.
      <br />
      <br />
      (b) Use any robot, spider, or other automatic device, process, or means to
      access Service for any purpose, including monitoring or copying any of the
      material on Service.
      <br />
      <br />
      (c) Use any manual process to monitor or copy any of the material on
      Service or for any other unauthorized purpose without our prior written
      consent.
      <br />
      <br />
      (d) Use any device, software, or routine that interferes with the proper
      working of Service.
      <br />
      <br />
      (e) Introduce any viruses, trojan horses, worms, logic bombs, or other
      material which is malicious or technologically harmful.
      <br />
      <br />
      (f) Attempt to gain unauthorized access to, interfere with, damage, or
      disrupt any parts of Service, the server on which Service is stored, or
      any server, computer, or database connected to Service.
      <br />
      <br />
      (g) Attack Service via a denial-of-service attack or a distributed
      denial-of-service attack.
      <br />
      <br />
      (h) Take any action that may damage or falsify Company rating.
      <br />
      <br />
      (i) Otherwise attempt to interfere with the proper working of Service.
      <br />
      <br />
      7. Analytics
      <br />
      <br />
      We may use third-party Service Providers to monitor and analyze the use of
      our Service.
      <br />
      <br />
      Google Analytics
      <br />
      Google Analytics is a web analytics service offered by Google that tracks
      and reports website traffic. Google uses the data collected to track and
      monitor the use of our Service. This data is shared with other Google
      services. Google may use the collected data to contextualise and
      personalise the ads of its own advertising network.
      <br />
      <br />
      For more information on the privacy practices of Google, please visit the
      Google Privacy Terms web page: https://policies.google.com/privacy?hl=en
      <br />
      <br />
      We also encourage you to review the Google's policy for safeguarding your
      data: https://support.google.com/analytics/answer/6004245.
      <br />
      <br />
      Firebase
      <br />
      Firebase is analytics service provided by Google Inc.
      <br />
      <br />
      You may opt-out of certain Firebase features through your mobile device
      settings, such as your device advertising settings or by following the
      instructions provided by Google in their Privacy Policy:
      https://policies.google.com/privacy?hl=en
      <br />
      <br />
      For more information on what type of information Firebase collects, please
      visit the Google Privacy Terms web page:
      https://policies.google.com/privacy?hl=en
      <br />
      <br />
      Mixpanel
      <br />
      Mixpanel is provided by Mixpanel Inc.
      <br />
      <br />
      You can prevent Mixpanel from using your information for analytics
      purposes by opting-out. To opt-out of Mixpanel service, please visit this
      page: https://mixpanel.com/optout/
      <br />
      <br />
      For more information on what type of information Mixpanel collects, please
      visit the Terms of Use page of Mixpanel: https://mixpanel.com/terms/
      <br />
      <br />
      8. No Use By Minors
      <br />
      Service is intended only for access and use by individuals at least
      eighteen (18) years old. By accessing or using any of Company, you warrant
      and represent that you are at least eighteen (18) years of age and with
      the full authority, right, and capacity to enter into this agreement and
      abide by all of the terms and conditions of Terms. If you are not at least
      eighteen (18) years old, you are prohibited from both the access and usage
      of Service.
      <br />
      <br />
      9. Accounts
      <br />
      When you create an account with us, you guarantee that you are above the
      age of 18, and that the information you provide us is accurate, complete,
      and current at all times. Inaccurate, incomplete, or obsolete information
      may result in the immediate termination of your account on Service.
      <br />
      <br />
      You are responsible for maintaining the confidentiality of your account
      and password, including but not limited to the restriction of access to
      your computer and/or account. You agree to accept responsibility for any
      and all activities or actions that occur under your account and/or
      password, whether your password is with our Service or a third-party
      service. You must notify us immediately upon becoming aware of any breach
      of security or unauthorized use of your account.
      <br />
      <br />
      You may not use as a username the name of another person or entity or that
      is not lawfully available for use, a name or trademark that is subject to
      any rights of another person or entity other than you, without appropriate
      authorization. You may not use as a username any name that is offensive,
      vulgar or obscene.
      <br />
      <br />
      We reserve the right to refuse service, terminate accounts, remove or edit
      content, or cancel orders in our sole discretion.
      <br />
      <br />
      10. Intellectual Property
      <br />
      Service and its original content (excluding Content provided by users),
      features and functionality are and will remain the exclusive property of
      GOLEARY LLC and its licensors. Service is protected by copyright,
      trademark, and other laws of the United States. Our trademarks and trade
      dress may not be used in connection with any product or service without
      the prior written consent of GOLEARY LLC.
      <br />
      <br />
      11. Error Reporting and Feedback
      <br />
      You may provide us either directly at oleary.gabe@gmail.com or via third
      party sites and tools with information and feedback concerning errors,
      suggestions for improvements, ideas, problems, complaints, and other
      matters related to our Service (“Feedback”). You acknowledge and agree
      that: (i) you shall not retain, acquire or assert any intellectual
      property right or other right, title or interest in or to the Feedback;
      (ii) Company may have development ideas similar to the Feedback; (iii)
      Feedback does not contain confidential information or proprietary
      information from you or any third party; and (iv) Company is not under any
      obligation of confidentiality with respect to the Feedback. In the event
      the transfer of the ownership to the Feedback is not possible due to
      applicable mandatory laws, you grant Company and its affiliates an
      exclusive, transferable, irrevocable, free-of-charge, sub-licensable,
      unlimited and perpetual right to use (including copy, modify, create
      derivative works, publish, distribute and commercialize) Feedback in any
      manner and for any purpose.
      <br />
      <br />
      The third party sites and tools mentioned above include the following:
      <br />
      <br />
      Sentry
      <br />
      Sentry is open-source error tracking solution provided by Functional
      Software Inc. More information is available here:
      https://sentry.io/privacy/
      <br />
      <br />
      12. Links To Other Web Sites
      <br />
      Our Service may contain links to third party web sites or services that
      are not owned or controlled by GOLEARY LLC.
      <br />
      <br />
      GOLEARY LLC has no control over, and assumes no responsibility for the
      content, privacy policies, or practices of any third party web sites or
      services. We do not warrant the offerings of any of these
      entities/individuals or their websites.
      <br />
      <br />
      YOU ACKNOWLEDGE AND AGREE THAT GOLEARY LLC SHALL NOT BE RESPONSIBLE OR
      LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED
      TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH
      CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY
      WEB SITES OR SERVICES.
      <br />
      <br />
      WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES
      OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
      <br />
      <br />
      13. Disclaimer Of Warranty
      <br />
      THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE”
      BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS
      OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION,
      CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE
      OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM
      US IS AT YOUR SOLE RISK.
      <br />
      <br />
      NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY
      OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY,
      QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE
      FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS
      OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS
      OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
      UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE
      SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
      COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH
      THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
      <br />
      <br />
      COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
      IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
      WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
      PARTICULAR PURPOSE.
      <br />
      <br />
      THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
      LIMITED UNDER APPLICABLE LAW.
      <br />
      <br />
      14. Limitation Of Liability
      <br />
      EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS,
      EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL,
      INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING
      ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND
      ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION
      OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT,
      NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION
      WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL
      INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION
      BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR
      REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE
      POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS
      LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT
      PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL
      THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
      EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES,
      SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
      <br />
      <br />
      15. Termination
      <br />
      We may terminate or suspend your account and bar access to Service
      immediately, without prior notice or liability, under our sole discretion,
      for any reason whatsoever and without limitation, including but not
      limited to a breach of Terms.
      <br />
      <br />
      If you wish to terminate your account, you may simply discontinue using
      Service.
      <br />
      <br />
      All provisions of Terms which by their nature should survive termination
      shall survive termination, including, without limitation, ownership
      provisions, warranty disclaimers, indemnity and limitations of liability.
      <br />
      <br />
      16. Governing Law
      <br />
      These Terms shall be governed and construed in accordance with the laws of
      State of Washington without regard to its conflict of law provisions.
      <br />
      <br />
      Our failure to enforce any right or provision of these Terms will not be
      considered a waiver of those rights. If any provision of these Terms is
      held to be invalid or unenforceable by a court, the remaining provisions
      of these Terms will remain in effect. These Terms constitute the entire
      agreement between us regarding our Service and supersede and replace any
      prior agreements we might have had between us regarding Service.
      <br />
      <br />
      17. Changes To Service
      <br />
      We reserve the right to withdraw or amend our Service, and any service or
      material we provide via Service, in our sole discretion without notice. We
      will not be liable if for any reason all or any part of Service is
      unavailable at any time or for any period. From time to time, we may
      restrict access to some parts of Service, or the entire Service, to users,
      including registered users.
      <br />
      <br />
      18. Amendments To Terms
      <br />
      We may amend Terms at any time by posting the amended terms on this site.
      It is your responsibility to review these Terms periodically.
      <br />
      <br />
      Your continued use of the Platform following the posting of revised Terms
      means that you accept and agree to the changes. You are expected to check
      this page frequently so you are aware of any changes, as they are binding
      on you.
      <br />
      <br />
      By continuing to access or use our Service after any revisions become
      effective, you agree to be bound by the revised terms. If you do not agree
      to the new terms, you are no longer authorized to use Service.
      <br />
      <br />
      19. Waiver And Severability
      <br />
      No waiver by Company of any term or condition set forth in Terms shall be
      deemed a further or continuing waiver of such term or condition or a
      waiver of any other term or condition, and any failure of Company to
      assert a right or provision under Terms shall not constitute a waiver of
      such right or provision.
      <br />
      <br />
      If any provision of Terms is held by a court or other tribunal of
      competent jurisdiction to be invalid, illegal or unenforceable for any
      reason, such provision shall be eliminated or limited to the minimum
      extent such that the remaining provisions of Terms will continue in full
      force and effect.
      <br />
      <br />
      20. Acknowledgement
      <br />
      BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT
      YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
      <br />
      <br />
      21. Contact Us
      <br />
      Please send your feedback, comments, requests for technical support:
      <br />
      By email: oleary.gabe@gmail.com.
      <Footer />
    </Layout>
  );
};

export default TermsPage;
